<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-if="['name', 'website'].includes(filterParam)"
        v-model="search"
        placeholder="Search"
        style="width: 270px"
        class="filter-item"
        clearable
        @clear="onFilter"
        @keyup.enter.native="onFilter"
      />

      <el-select
        v-if="filterParam === 'isActive'"
        v-model="filterSearch"
        style="width: 270px"
        class="filter-item"
      >
        <el-option
          v-for="item in statuses"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>

      <el-select
        v-if="filterParam === 'categoryUid'"
        v-model="filterSearch"
        filterable
        style="width: 270px"
        class="filter-item"
      >
        <el-option
          v-for="item in categories"
          :key="item.categoryUid"
          :label="item.name"
          :value="item.categoryUid"
        />
      </el-select>

      <el-select
        v-model="filterParam"
        style="width: 130px"
        class="filter-item"
      >
        <el-option
          v-for="item in filterOptions"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>

      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        :loading="listLoading"
        @click="onFilter"
      />

      <el-button
        class="filter-item"
        type="default"
        icon="el-icon-refresh-right"
        :loading="listLoading"
        @click="onFilterReset"
      />

      <el-button
        v-permission="['admin']"
        class="filter-item fr"
        type="primary"
        icon="el-icon-circle-plus-outline"
        :loading="listLoading"
        @click="handleNewStore"
      >
        New Store
      </el-button>
    </div>

    <el-table
      ref="table"
      v-loading="listLoading"
      :data="list"
      border
      :max-height="maxHeight"
      @sort-change="handleSort"
    >
      <el-table-column
        label="Name"
        width="150"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="linkView('StoreView', row.storeUid, $event)"
          >
            {{ row.name }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Website"
      >
        <template slot-scope="{ row }">
          <span>{{ row.website }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Category"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="linkView('CategoryView', row.categoryUid, $event)"
          >
            {{ row.categoryName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Active"
        width="80"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.isActive ? 'success' : 'danger'">
            {{ row.isActive ? 'Yes' : 'No' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="Parser Enabled"
        width="120"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.isParserEnabled ? 'success' : 'danger'">
            {{ row.isParserEnabled ? 'Yes' : 'No' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="Parser Status"
        width="150"
        sortable="custom"
        prop="priority"
      >
        <template slot-scope="{ row }">
          <span>{{ row.parserStatus }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Priority"
        width="150"
        sortable="custom"
        prop="priority"
      >
        <template slot-scope="{ row }">
          <span>{{ row.priority }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Created At"
        width="150"
        sortable="custom"
        prop="createdAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Updated At"
        width="150"
        sortable="custom"
        prop="updatedAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.updatedAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-permission="['admin']"
        align="center"
        width="130"
      >
        <template
          slot-scope="{ row }"
        >
          <el-button
            v-permission="['admin']"
            type="primary"
            size="mini"
            circle
            plain
            title="Edit"
            @click.stop="handleStoreEdit(row.storeUid)"
          >
            <i class="far fa-edit"></i>
          </el-button>

          <el-button
            v-permission="['admin']"
            type="danger"
            size="mini"
            circle
            plain
            title="Delete"
            @click.stop="handleStoreDelete(row.storeUid)"
          >
            <i class="far fa-trash"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-show="total > 0"
      :pager-count="5"
      class="pagination"
      background
      layout="total, prev, pager, next, sizes"
      :current-page.sync="listQuery.pageNumber"
      :page-size.sync="listQuery.pageSize"
      :total="total"
      @size-change="getList"
      @current-change="getList"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      top="25px"
    >
      <div>
        <store-details
          :is-edit-mode="isEditMode"
          :store-uid="activeStoreUid"
          :visible.sync="dialogVisible"
          @update="getList(true)"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { listStores, deleteStore } from '@/api/store';
import { getAllCategories } from '@/api/category';
import maxHeightMixin from '@/mixins/maxHeight';
import permission from '@/directive/permission';
import linkViewMixin from '@/mixins/linkView';
import StoreDetails from './components/storeDetails';

export default {
  name: 'StoreList',
  directives: {
    permission,
  },
  components: {
    StoreDetails,
  },
  mixins: [maxHeightMixin, linkViewMixin],
  data() {
    const pageSize = +localStorage.getItem('defPageSize');
    const defSortBy = 'name';
    const defSortDir = 'asc';

    return {
      list: null,
      total: 0,
      defSortBy,
      defSortDir,
      listLoading: true,
      filterParam: 'name',
      filterOptions: [
        { key: 'name', label: 'Name' },
        { key: 'website', label: 'Website' },
        { key: 'isActive', label: 'Active' },
        { key: 'categoryUid', label: 'Category' },
      ],
      statuses: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
      categories: [],
      search: '',
      filterSearch: '',
      listQuery: {
        pageNumber: 1,
        pageSize: pageSize || 20,
        sortBy: defSortBy,
        sortDir: defSortDir,
      },
      dialogVisible: false,
      isEditMode: false,
      activeStoreUid: null,
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'StoreList') {
        this.getList();
      }
    },
  },
  created() {
    this.getList();
    this.getCategories();
  },
  activated() {
    this.getList(true);
  },
  methods: {
    async getList(refresh = false) {
      try {
        this.listLoading = true;

        if (!refresh) {
          this.list = [];
          this.listQuery.pageNumber = 1;
        }

        const data = await listStores(this.listQuery);
        const { items, pageInfo } = data.page;

        this.list = items;
        this.total = pageInfo.itemsAvailable;
        this.listLoading = false;

        localStorage.setItem('defPageSize', this.listQuery.pageSize);
      } catch (e) {
        this.listLoading = false;
      }
    },
    async getCategories() {
      const data = await getAllCategories();
      this.categories = data.categories;
    },
    handleSort({ prop, order }) {
      if (order !== null) {
        this.listQuery.sortBy = prop;
        this.listQuery.sortDir = order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.listQuery.sortBy = this.defSortBy;
        this.listQuery.sortDir = this.defSortDir;
      }

      this.getList();
    },
    handleNewStore() {
      this.activeStoreUid = null;
      this.isEditMode = false;
      this.dialogVisible = true;
    },
    handleStoreEdit(storeUid) {
      this.activeStoreUid = storeUid;
      this.isEditMode = true;
      this.dialogVisible = true;
    },
    handleStoreDelete(storeUid) {
      this.$confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        deleteStore({ storeUid }).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete successful',
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },
    onFilter() {
      let search;

      if (this.filterParam === 'isActive') {
        search = this.filterSearch;
      } else if (this.filterParam === 'categoryUid') {
        search = this.filterSearch;
      } else {
        search = this.search.trim();
      }

      const isInput = ['name', 'website', 'isActive'].includes(this.filterParam);

      if ((isInput && search.length > 0) || (!isInput && search !== 'all')) {
        this.listQuery.filter = JSON.stringify({
          [this.filterParam]: search,
        });
      } else {
        this.setDefaultFilter();
      }

      this.getList();
    },
    onFilterReset() {
      this.search = '';
      this.filterParam = 'name';

      this.setDefaultFilter();
      this.getList();
    },
    setDefaultFilter() {
      this.listQuery.filter = JSON.stringify({});
    },
  },
};
</script>
