<template>
  <div class="flex-form">
    <el-form
      ref="storeForm"
      :loading="loading"
      :model="storeForm"
      label-width="160px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Store
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Store
        </h2>
      </el-form-item>

      <el-tabs
        v-model="formTabsValue"
        class="el-form-item__content"
      >
        <el-tab-pane
          label="Main"
          name="main"
        >
          <el-form-item
            v-if="isEditMode"
            label="Uid"
          >
            {{ storeUid }}
          </el-form-item>

          <el-form-item
            label="Active"
            prop="isActive"
          >
            <el-switch
              v-model="storeForm.isActive"
            />
          </el-form-item>

          <el-form-item
            label="Category"
            prop="categoryUid"
          >
            <el-select
              v-model="storeForm.categoryUid"
            >
              <el-option
                v-for="item in categories"
                :key="item.categoryUid"
                :label="item.name"
                :value="item.categoryUid"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Name"
            prop="name"
          >
            <el-input v-model="storeForm.name" />
          </el-form-item>

          <el-form-item
            label="Relative Url"
            prop="relativeUrl"
          >
            <el-input v-model="storeForm.relativeUrl" />
          </el-form-item>

          <el-form-item
            label="Header Title"
            prop="headerTitle"
          >
            <el-input v-model="storeForm.headerTitle" />
          </el-form-item>

          <el-form-item
            label="Header Text"
            prop="headerText"
          >
            <el-input
              v-model="storeForm.headerText"
              resize="none"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>

          <el-form-item
            label="Content Text"
            prop="contentText"
          >
            <html-editor
              :content="storeForm.description || ''"
              :height="250"
              @change="updateEditorData"
            />
          </el-form-item>

          <el-form-item
            label="Content Text 2"
            prop="contentText2"
          >
            <html-editor
              :content="storeForm.description2 || ''"
              :height="250"
              @change="updateEditorData2"
            />
          </el-form-item>

          <el-form-item
            label="Website"
            prop="website"
          >
            <el-input v-model="storeForm.website" />
          </el-form-item>

          <el-form-item
            label="Meta Title"
            prop="metaTitle"
          >
            <el-input v-model="storeForm.metaTitle" />
          </el-form-item>

          <el-form-item
            label="Meta Description"
            prop="metaDescription"
          >
            <el-input
              v-model="storeForm.metaDescription"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>

          <el-form-item
            label="Parser Name"
            prop="parserName"
          >
            <el-input v-model="storeForm.parserName" />
          </el-form-item>

          <el-form-item
            label="Parser Enabled"
            prop="isParserEnabled"
          >
            <el-switch
              v-if="isEditMode"
              v-model="storeForm.isParserEnabled"
            />
          </el-form-item>

          <el-form-item
            label="Priority"
            prop="priority"
          >
            <el-input v-model="storeForm.priority" />
          </el-form-item>

          <el-form-item
            label="Background Color"
            prop="bgColor"
          >
            <el-color-picker v-model="storeForm.bgColor" />
          </el-form-item>

          <el-form-item
            label="Border Color"
            prop="circleColor"
          >
            <el-color-picker v-model="storeForm.circleColor" />
          </el-form-item>

          <!-- <el-form-item
            label="Circle Color Alt"
            prop="circleColorAlt"
          >
            <el-color-picker v-model="storeForm.circleColorAlt" />
          </el-form-item> -->

          <el-form-item
            label="Text Color"
            prop="textColor"
          >
            <el-color-picker v-model="storeForm.textColor" />
          </el-form-item>

          <el-form-item
            label="Icon"
          >
            <upload-media
              ref="uploadIcon"
              :image-url="iconUrl"
              @success="handleIconUploadSuccess"
            />
          </el-form-item>

          <el-form-item
            label="Logo"
          >
            <upload-media
              ref="uploadLogo"
              :image-url="logoUrl"
              @success="handleImageUploadSuccess"
            />
          </el-form-item>

          <el-form-item
            label="Facebook"
            prop="facebook"
          >
            <el-input v-model="storeForm.facebook" />
          </el-form-item>

          <el-form-item
            label="Twitter"
            prop="twitter"
          >
            <el-input v-model="storeForm.twitter" />
          </el-form-item>

          <el-form-item
            label="Instagram"
            prop="instagram"
          >
            <el-input v-model="storeForm.instagram" />
          </el-form-item>

          <el-form-item
            label="LinkedIn"
            prop="linkedIn"
          >
            <el-input v-model="storeForm.linkedIn" />
          </el-form-item>

          <el-form-item
            label="Youtube"
            prop="youtube"
          >
            <el-input v-model="storeForm.youtube" />
          </el-form-item>

          <el-form-item
            label="Pinterest"
            prop="pinterest"
          >
            <el-input v-model="storeForm.pinterest" />
          </el-form-item>

          <el-form-item
            label="TikTok"
            prop="tiktok"
          >
            <el-input v-model="storeForm.tiktok" />
          </el-form-item>

          <el-form-item
            label="Note"
            prop="note"
          >
            <el-input
              v-model="storeForm.note"
              resize="none"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>

          <el-form-item
            label="Is Tag"
            prop="isTag"
          >
            <el-switch
              v-model="storeForm.isTag"
            />
          </el-form-item>

          <el-form-item
            v-if="storeForm.isTag"
            label="Tag"
            prop="tag"
          >
            <el-input v-model="storeForm.tag" />
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane
          label="Static pages"
          name="staticPages"
        >
          <el-tabs
            v-model="pageTabsValue"
            type="card"
            editable
            @edit="handleTabsEdit"
          >
            <el-tab-pane
              v-for="(item, index) in pageTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              <el-form-item
                label="Name"
                :prop="'staticPages.' + index + '.name'"
              >
                <el-input
                  v-model="storeForm.staticPages[index].name"
                  @input.native="handleStaticPageNameChange(index)"
                />
              </el-form-item>

              <el-form-item
                label="Match Text"
                :prop="'staticPages.' + index + '.matchText'"
              >
                <el-select
                  v-model="storeForm.staticPages[index].matchRule"
                  placeholder="Select"
                  style="width: 28%"
                >
                  <el-option
                    v-for="item2 in matchOptions"
                    :key="item2.value"
                    :label="item2.label"
                    :value="item2.value"
                  />
                </el-select>
                <el-input
                  v-model="storeForm.staticPages[index].matchText"
                  style="width: 70%; float: right"
                />
              </el-form-item>

              <el-form-item
                label="Is Popular"
                :prop="'staticPages.' + index + '.isPopular'"
              >
                <el-switch
                  v-model="storeForm.staticPages[index].isPopular"
                />
              </el-form-item>

              <div
                v-for="index2 in 3"
                :key="index2"
              >
                <el-divider content-position="left">
                  Page {{ index2 }}
                  <span v-if="index2 === 1">
                    (main)
                  </span>
                </el-divider>

                <el-form-item
                  label="Relative Url"
                  :prop="'staticPages.' + index + '.relativeUrl' + index2"
                >
                  <el-input v-model="storeForm.staticPages[index]['relativeUrl' + index2]" />
                </el-form-item>

                <el-form-item
                  label="Title"
                  :prop="'title' + index + index2"
                >
                  <el-input v-model="storeForm.staticPages[index]['title' + index2]" />
                </el-form-item>

                <el-form-item
                  label="Description"
                  :prop="'description' + index + index2"
                >
                  <html-editor
                    :content="storeForm.staticPages[index]['description' + index2] || ''"
                    :height="130"
                    @change="updateEditorData4($event, index, index2)"
                  />
                </el-form-item>

                <el-form-item
                  label="Content Text"
                  :prop="'descriptionAlt' + index + index2"
                >
                  <html-editor
                    :content="storeForm.staticPages[index]['descriptionAlt' + index2] || ''"
                    :height="250"
                    @change="updateEditorData3($event, index, index2)"
                  />
                </el-form-item>

                <el-form-item
                  label="Meta Title"
                  :prop="'metaTitle' + index + index2"
                >
                  <el-input v-model="storeForm.staticPages[index]['metaTitle' + index2]" />
                </el-form-item>

                <el-form-item
                  label="Meta Description"
                  :prop="'metaDescription' + index + index2"
                >
                  <el-input
                    v-model="storeForm.staticPages[index]['metaDescription' + index2]"
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 2, maxRows: 8}"
                  />
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getStore, updateStore, createStore } from '@/api/store';
import { getAllCategories } from '@/api/category';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'StoreDetails',
  components: { UploadMedia },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    storeUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      matchOptions: [{
        value: 'contains',
        label: 'Contains',
      }, {
        value: 'exact',
        label: 'Exact match',
      }, {
        value: 'endsWith',
        label: 'Ends with',
      }, {
        value: 'startsWith',
        label: 'Starts with',
      }],
      formTabsValue: 'main',
      pageTabsValue: '0',
      tabIndex: 0,
      pageTabs: [/* {
        title: 'Group 1',
        name: '1',
      } */ ],
      loading: false,
      categoriesLoading: false,
      saving: false,
      storeForm: {
        categoryUid: null,
        name: '',
        description: '',
        contentText: '',
        headerTitle: '',
        headerText: '',
        metaTitle: '',
        metaDescription: '',
        metaDescription2: '',
        isActive: true,
        parserName: '',
        isParserEnabled: false,
        iconMediaUid: null,
        logoMediaUid: null,
        isTag: false,
        tag: '',
        staticPages: [/* {
          name: '',
          matchRule: '',
          matchText: '',
          relativeUrl1: '',
          description1: '',
          metaTitle1: '',
          metaDescription1: '',
          relativeUrl2: '',
          description2: '',
          metaTitle2: '',
          metaDescription2: '',
          relativeUrl3: '',
          description3: '',
          metaTitle3: '',
          metaDescription3: '',
        } */],
      },
      store: {},
      categories: {},
      rules: {
        categoryUid: [{
          required: true,
          trigger: 'blur',
          message: 'Category is required',
        }],
        name: [{
          required: true,
          trigger: 'blur',
          message: 'Username is required',
        }],
        relativeUrl: [{
          required: true,
          trigger: 'blur',
          message: 'Relative Url is required',
        }],
        headerText: [{
          required: true,
          trigger: 'blur',
          message: 'Header Text is required',
        }],
        description: [{
          required: true,
          trigger: 'blur',
          message: 'Description is required',
        }],
        /* website: [{
          required: true,
          trigger: 'blur',
          message: 'Website is required',
        }], */
        priority: [{
          required: true,
          trigger: 'blur',
          message: 'Priority is required',
        }],
      },
    };
  },
  computed: {
    iconUrl() {
      return this.storeForm?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.storeForm?.media?.logo?.image?.location;
    },
  },
  watch: {
    storeUid(val) {
      if (val) {
        this.getStore(val);
      } else {
        this.resetForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getStore(this.storeUid);
    }

    this.getCategories();
  },
  methods: {
    async getStore(storeUid) {
      try {
        this.resetForm();
        this.loading = true;
        const data = await getStore({ storeUid });
        if (!data.store?.staticPages) {
          data.store.staticPages = [];
          /* data.store.staticPages = [{
            name: 'Group 1',
            matchRule: 'contains',
            matchText: '',
            relativeUrl1: '',
            description1: '',
            title: '',
            metaTitle1: '',
            metaDescription1: '',
            relativeUrl2: '',
            description2: '',
            title2: '',
            metaTitle2: '',
            metaDescription2: '',
            relativeUrl3: '',
            title3: '',
            description3: '',
            metaTitle3: '',
            metaDescription3: '',
          }]; */
        }

        this.store = cloneDeep(data.store);
        this.storeForm = data.store;

        if (data.store?.staticPages?.length > 0) {
          this.pageTabsValue = '1';
          this.tabIndex = 1;

          this.pageTabs = data.store.staticPages.map((page, index) => ({
            title: page.name || `Group ${index + 1}`,
            name: `${index + 1}`,
          }));

          data.store.staticPages.forEach((page, index) => {
            this.addStaticPageRules(index);
          });
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getCategories() {
      try {
        this.categoriesLoading = true;
        const data = await getAllCategories();

        this.categories = data.categories;
        this.categoriesLoading = false;
      } catch (e) {
        this.categoriesLoading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.storeForm.validate(async (valid) => {
        if (valid) {
          const updatedStore = makeUpdateObj(this.store, this.storeForm);
          if (Object.keys(updatedStore).length > 0) {
            try {
              this.saving = true;
              updatedStore.storeUid = this.storeUid;

              // console.log(updatedStore);
              await updateStore(updatedStore);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              await this.getStore(this.storeUid);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.storeForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;

            await createStore(this.storeForm);

            this.$message({
              type: 'success',
              message: 'Store has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.pageTabs.forEach((tab, index) => {
        this.removeStaticPageRules(index);
      });

      this.formTabsValue = 'main';
      this.pageTabsValue = '0';
      this.tabIndex = 0;
      this.pageTabs = [/* {
        title: 'Group 1',
        name: '1',
      } */];
      this.$nextTick(() => {
        this.$refs.storeForm.resetFields();
        this.$refs.uploadIcon.reset();
        this.$refs.uploadLogo.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleIconUploadSuccess(data) {
      this.storeForm.iconMediaUid = data.mediaUid;
    },
    handleImageUploadSuccess(data) {
      this.storeForm.logoMediaUid = data.mediaUid;
    },
    updateEditorData(data) {
      this.storeForm.description = data;
    },
    updateEditorData2(data) {
      this.storeForm.description2 = data;
    },
    updateEditorData3(data, index, index2) {
      this.storeForm.staticPages[index][`descriptionAlt${index2}`] = data;
    },
    updateEditorData4(data, index, index2) {
      this.storeForm.staticPages[index][`description${index2}`] = data;
    },
    handleTabsEdit(targetName, action) {
      if (action === 'add') {
        // const index = this.tabIndex;
        const index = this.pageTabs.length + 1;
        this.tabIndex = index;
        const newTabName = index.toString();

        this.storeForm.staticPages.push({
          name: `Group ${newTabName}`,
          matchRule: 'contains',
          matchText: '',
          isPopular: false,
          relativeUrl1: '',
          description1: '',
          descriptionAlt1: '',
          title1: '',
          metaTitle1: '',
          metaDescription1: '',
          relativeUrl2: '',
          description2: '',
          descriptionAlt2: '',
          title2: '',
          metaTitle2: '',
          metaDescription2: '',
          relativeUrl3: '',
          title3: '',
          description3: '',
          descriptionAlt3: '',
          metaTitle3: '',
          metaDescription3: '',
        });

        this.pageTabs.push({
          title: `Group ${newTabName}`,
          name: newTabName,
        });
        this.pageTabsValue = newTabName;

        this.addStaticPageRules(index - 1);
      }
      if (action === 'remove') {
        // const index = this.tabIndex;
        let index = null;
        const tabs = this.pageTabs;
        let activeName = this.pageTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, i) => {
            if (tab.name === targetName) {
              const nextTab = tabs[i + 1] || tabs[i - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        tabs.forEach((tab, i) => {
          if (tab.name === targetName) {
            index = i;
          }
        });

        if (index) {
          this.removeStaticPageRules(index);
        }

        this.storeForm.staticPages.splice(index, 1);

        this.pageTabsValue = activeName;
        this.pageTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
    handleStaticPageNameChange() {
      const { name } = this.storeForm.staticPages[+this.pageTabsValue - 1];
      this.pageTabs[+this.pageTabsValue - 1].title = name;
    },
    addStaticPageRules(index) {
      this.rules[`staticPages.${index}.name`] = [{
        required: true,
        trigger: 'blur',
        message: 'Static page name is required',
      }];
      this.rules[`staticPages.${index}.matchText`] = [{
        required: true,
        trigger: 'blur',
        message: 'Static page match text is required',
      }];
      /* this.rules[`staticPages.${index}.relativeUrl1`] = [{
        required: true,
        trigger: 'blur',
        message: 'Static page relative url is required1',
      }]; */
    },
    removeStaticPageRules(index) {
      delete this.rules[`staticPages.${index}.name`];
      delete this.rules[`staticPages.${index}.matchText`];
      delete this.rules[`staticPages.${index}.relativeUrl`];
    },
  },
};
</script>

<style lang="scss">
.flex-form {
  .el-tabs__header,
  .el-divider {
    margin-left: 160px;
  }
}
</style>
